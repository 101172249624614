import React from "react"
import "./Form.css"

const Form = props => (
  <form
    action="https://public.herotofu.com/v1/6cae8350-6027-11ec-b63a-19467ff46d66"
    method="post"
    id="contactForm"
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "1em",
      margin: "0 auto",
      textAlign: "left",
      gap: "8px",
    }}
  >
    <div>
      <label for="name" htmlFor="name">
        Your Name
      </label>
      <input
        name="Name"
        id="name"
        type="text"
        placeholder="John Doe"
        required
      />
    </div>
    <div>
      <label for="email" htmlFor="email">
        Your Email
      </label>
      <input
        name="Email"
        id="email"
        type="email"
        placeholder="JDoe@gmail.com"
        required
      />
    </div>
    <div>
      <label for="number" htmlFor="number">
        Your Telephone Number
      </label>
      <input
        name="Number"
        id="number"
        type="number"
        placeholder="01282123456"
        required
      />
    </div>
    <div>
      <label for="message" htmlFor="messsage" required>
        Message
      </label>
      <textarea
        name="message"
        rows="10"
        cols="30"
        placeholder="Hello, I would like help with the following"
      />
    </div>
    <div>
      <input type="submit" value="Submit Form" />
    </div>
  </form>
)

export default Form
