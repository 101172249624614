import React from "react"
import Form from "./Form"
import MyMap from "./GoogleMap"

const ContactSection = ({ siteTitle }) => (
  <section
    id="contact"
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      maxWidth: 1280,
      margin: `0 auto`,
      gap: "20px",
    }}
  >
    <MyMap />
    <Form />
  </section>
)

export default ContactSection
