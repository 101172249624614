import React from "react"
import FullStory from "./FullStory"
import Story from "./Story"

import Placeholder from "../../images/storys/placeholder.jpeg"
import Tommy from "../../images/storys/tommy.jpeg"

const StorySection = ({ siteTitle }) => (
  <section
    id="projects"
    style={{
      textAlign: "center",
      display: "grid",
      margin: `0 auto`,
      maxWidth: 1280,
    }}
  >
    <h2>Our Stories</h2>
    <div
      id="Story"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "20px",
        maxWidth: 1280,
      }}
    >
      <Story
        person="John"
        image={Placeholder}
        story="John had decided to buy himself a Smart TV as he had heard of services like BBC iPlayer 
        and wanted to use them himself however he found out when he got him with the TV that he
        required an internet connection to use these services."
        fullstory={
          <FullStory
            image={Placeholder}
            person="John"
            story={
              <div>
                <p>
                  John had decided to buy himself a Smart TV as he had heard of
                  services like BBC iPlayer and wanted to use them himself
                  however he found out when he got him with the TV that he
                  required an internet connection to use these services.
                </p>
                <p>
                  John hadn't much experience of using the internet so we
                  decided to help him get up and running with everything he
                  required to use the internet for his needs.
                </p>
                <p>
                  From our initial assessment, we identified that since he lived
                  in a rural location this meant broadband speeds were low and
                  he had no requirement for a landline, our solution was to
                  provide him with a 4G WiFi router to get him up and running
                  online as well as providing him with an email address and
                  registered him up to the services he wanted to use, now he can
                  experience all these different services and find more things
                  he enjoys online.
                </p>
              </div>
            }
          />
        }
      />
      <Story
        person="Tommy"
        image={Tommy}
        story={
          <div>
            <p>
              Tommy a life long football fan, used to attend all the football
              games but due to his health, he was unable to go to watch his team
              any more.
            </p>
            <p>
              We ensured that Tommy could watch all the football matches of his
              team by equipping him with the technology and services he required
              so he could watch them in the comfort of his own home.
            </p>
          </div>
        }
        fullstory={
          <FullStory
            image={Tommy}
            person="Tommy"
            story={
              <div>
                <p>
                  Tommy a life long football fan, used to attend all the
                  football games but due to his health, he was unable to go to
                  watch his team any more.
                </p>
                <p>
                  We ensured that Tommy could watch all the football matches of
                  his team by equipping him with the technology and services he
                  required so he could watch them in the comfort of his own
                  home.
                </p>
              </div>
            }
          />
        }
      />
      <Story
        person="Paul"
        image={Placeholder}
        story="Paul bought himself a new mobile phone and was having issues when trying to log
        into his accounts as he had forgotten, account details and then on some applications, he was unable
        to login due to security reasons."
        fullstory={
          <FullStory
            image={Placeholder}
            person="Paul"
            story={
              <div>
                <p>
                  Paul bought himself a new mobile phone and was having issues
                  when trying to log into his accounts as he had forgotten,
                  account details and then on some applications, he was unable
                  to login due to security reasons.
                </p>
                <p>
                  He began to get frustrated with this process so we provided
                  Paul with a Password manager so he could store all of his
                  accounts in one safe and secure account, His log-In experience
                  could become seamless, by using autofill the password manager
                  could generate new passwords for him, so he didn't have to and
                  keeps him more secure by using different passwords for various
                  services.
                </p>
                <p>
                  Additionally, on those accounts that he had security issues;
                  this was due to his device having no lock so by setting him up
                  with facial recognition on his device he could now access the
                  account he previously couldn't on his mobile phone.
                </p>
                <p>
                  Paul is now able to manage his accounts from his phone, tablet
                  and computer and always available for when he needs it.{" "}
                </p>
              </div>
            }
          />
        }
      />
    </div>
  </section>
)

export default StorySection
