import React from "react"
import Modal from "./Model"

class Story extends React.Component {
  state = {
    show: false,
  }
  showModal = e => {
    this.setState({
      show: !this.state.show,
    })
  }
  render() {
    return (
      <div className="Story">
        <div className="StoryContents">
          <img
            src={this.props.image}
            alt={this.props.person}
            style={{
              borderRadius: "100%",
              maxWidth: "150px",
              maxHeight: "150px",
            }}
          />
          <h3>{this.props.person}</h3>
          <p>{this.props.story}</p>

          <button
            class="toggle-button"
            id="centered-toggle-button"
            onClick={e => {
              this.showModal(e)
            }}
          >
            Read {this.props.person}'s Full Story
          </button>
        </div>
        <Modal
          onClose={this.showModal}
          show={this.state.show}
          person={this.props.person}
        >
          {this.props.fullstory}
        </Modal>
      </div>
    )
  }
}

export default Story
