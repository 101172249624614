import * as React from "react"

const FullStory = props => (
  <>
    <img
      src={props.image}
      alt={props.person}
      style={{
        borderRadius: "100%",
        maxWidth: "150px",
        maxHeight: "150px",
      }}
    />
    <h3>{props.person}</h3>
    {props.story}
  </>
)

export default FullStory
