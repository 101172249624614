import * as React from "react"

const Article = props => (
  <div>
    <h2
      style={{
        fontSize: `16pt`,
      }}
    >
      {props.title}
    </h2>
    <p>{props.article}</p>
  </div>
)

export default Article
