import { Link } from "gatsby"
import * as React from "react"
import Article from "./article"
import Step from "./steps"

const Info = props => (
  <section
    id="info"
    style={{
      textAlign: "center",
      display: "grid",
      margin: `0 auto`,
      maxWidth: 1280,
    }}
  >
    <h1
      style={{
        fontSize: `18pt`,
      }}
    >
      {props.heading}
    </h1>
    <p
      style={{
        fontSize: `14pt`,
      }}
    >
      {props.text}
    </p>
    <h2
      style={{
        fontSize: `16pt`,
      }}
    >
      {props.subheading}
    </h2>
    <div
      id="steps"
      style={{
        display: `flex`,
        justifyContent: "center",
        marginBottom: "16px",
      }}
    >
      <Step
        number="1"
        heading="Call Us Now"
        text="Smart-Home us for a free 1-1 in Home Review"
      />
      <Step
        number="2"
        heading="Review Our Recomendations"
        text="Review our recomendations to see if they fit your needs"
      />
      <Step
        number="3"
        heading="Get You Up & Running"
        text="Let us get you all set up with what you need"
      />
    </div>
    <div
      style={{
        display: `grid`,
        gap: "8px",
      }}
    >
      <Link
        to={props.link}
        style={{
          padding: `8px 16px`,
          fontSize: "14pt",
          fontWeight: "bold",
          borderRadius: "10em",
          backgroundColor: "#315062",
          color: "#fff",
          textDecoration: "none",
        }}
      >
        {props.linktext}
      </Link>
      <Link
        to={props.link2}
        style={{
          padding: `8px 16px`,
          fontSize: "14pt",
          fontWeight: "bold",
          borderRadius: "10em",
          backgroundColor: "#fff",
          boxSizing: "border-box",
          border: "4px solid #315062",
          color: "#315062",
          textDecoration: "none",
        }}
      >
        {props.linktext2}
      </Link>
    </div>
    <article
      style={{
        marginTop: `16px`,
        textAlign: "left",
      }}
    >
      <h1
        style={{
          marginTop: `16px`,
          fontSize: '18pt',
          textAlign: "center",
        }}
      >
        {props.title}
      </h1>
      <Article
        title="Who We Are"
        article="A family team focussed on helping others in the use of their technology, having
                        using technology
                        daily over the past 20 years we have had a lot of joy out of using it and we want others to
                        experience the
                        same joy as we've had"
      />
      <Article
        title="What We Do"
        article=" We seek to help people who are new to using technology or wanting to try
                        something new, on
                        their feet with there technology and ensure that they get the most out of their technology
                        and take
                        away all the hassle involved in getting the most out of today's technology
                    "
      />
      <Article
        title="Why Use Us"
        article=" We want to help people get the most of their technology and are willing to help
                        you
                        develop your knowledge and understanding with technology as we have 20 years of experience
                        of using
                        technology daily and want others to get as much enjoyment as we do out of technology "
      />
      <Article
        title="How We Help"
        article=" We get you registered for your preferred services, we can install applications on
                        your smart
                        devices & we get you familiar with and able to use the apps and services on your devices
                        also we can advise
                        on devices and services to fit your needs "
      />
    </article>
  </section>
)

export default Info
