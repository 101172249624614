import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Info from "../components/info"
import MyServices from "../components/Devices/MyServices"
import Story from "../components/Storys/Story"
import StorySection from "../components/Storys/Section"
import ContactSection from "../components/Contact/Section"

const IndexPage = () => (
  <Layout>
    <Seo title="Smart Home Help" />
    <Info
      heading="Getting the most out of your Smart Devices?"
      text="Unsure what to do or fustrated by the Jargan?"
      subheading="We can Make it as easy as 1 2 3"
      link="#contact"
      linktext="Contact Us"
      link2="#services"
      linktext2="Discover what you can get the most out of ▼"
      title="About Us"
    />
    <MyServices />
    <StorySection>
      <Story />
    </StorySection>
    <ContactSection />
  </Layout>
)

export default IndexPage
