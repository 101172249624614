import React from "react"
import "./Service.css"

const Service = props => (
  <li className="Service-Object">
    <div className="Service-Contents">
      <img
        src={props.product}
        id={props.id}
        alt={props.alt}
        formats={["auto", "webp", "avif"]}
        style={{ maxWidth: "100%", marginLeft: "auto", marginRight: "auto" }}
      />
      <h3>{props.productname}</h3>
      <p className="Service-Text">{props.text}</p>
    </div>
  </li>
)

export default Service
