import React from "react"
import Tabs from "./ServiceTabs"
import Service from "./Service"
import "./Service.css"

import TV from "../../images/services/devices/tv.png"
import Desktop from "../../images/services/devices/desktop.png"
import Laptop from "../../images/services/devices/laptop.png"
import Tablet from "../../images/services/devices/tablet.png"
import Mobile from "../../images/services/devices/mobile.png"

import BBC from "../../images/services/services/iplayer.webp"
import ITV from "../../images/services/services/itv-hub.webp"
import fourall from "../../images/services/services/4all.webp"
import Spotify from "../../images/services/services/spotify.webp"
import Netlfix from "../../images/services/services/netflix.webp"
import PrimeVideo from "../../images/services/services/prime.webp"

import Alexa from "../../images/services/smart-home/alexa.webp"
import Google from "../../images/services/smart-home/google-home.webp"
import Hive from "../../images/services/smart-home/hive.webp"
import Hue from "../../images/services/smart-home/hue.webp"

import Login from "../../images/services/security/login.svg"
import Password from "../../images/services/security/password.svg"
import VPN from "../../images/services/security/vpn.svg"

const MyServices = ({ siteTitle }) => (
  <section
    id="services"
    style={{
      borderBottom: "1px solid #eee",
      background: "#ededed",
      textAlign: "center",
    }}
  >
    <h2>Get the most out of your Smart Technolgy</h2>
    <Tabs>
      <div className="MyServices" label="Devices">
        <ul className="Service-Group">
          <Service
            product={TV}
            productname="Smart TV"
            text="Setup your Smart TV with free catch up & On demand services"
          />
          <Service
            product={Desktop}
            productname="Desktop"
            text="Get your Desktop set up with the apps you need"
          />
          <Service
            product={Laptop}
            productname="Laptop"
            text="Get your Laptop set up with the apps you need"
          />
          <Service
            product={Tablet}
            productname="Tablet"
            text="Transfer everything you want from your phone"
          />
          <Service
            product={Mobile}
            productname="Mobile Phone"
            text="Want to know how to keep all your messages when moving device"
          />
        </ul>
      </div>
      <div className="MyServices" label="Services">
        <ul className="Service-Group">
          <Service
            product={BBC}
            productname="BBC iPlayer"
            text="Setup your Smart TV with free catch up & On demand services"
          />
          <Service
            product={ITV}
            productname="ITV Hub"
            text="Setup itv Hub on any device anywhere"
          />
          <Service
            product={fourall}
            productname="4all"
            text="Setup 4all on any device anywhere"
          />
          <Service
            product={Spotify}
            productname="Spotify"
            text="Get the highest quality audio on Spotify"
          />
          <Service
            product={Netlfix}
            productname="Netflix"
            text="Watching Netflix anywhere in the world"
          />
          <Service
            product={PrimeVideo}
            productname="Amazon Prime"
            text="Get setup with 4K on Amazon Prime"
          />
        </ul>
      </div>
      <div className="MyServices" label="Smart Home">
        <ul className="Service-Group">
          <Service
            product={Alexa}
            productname="Alexa"
            text="Get Alexa taliored to know your needs"
          />
          <Service
            product={Google}
            productname="Google/Nest Home"
            text="Get setup with your devices and control them from anywhere"
          />
          <Service
            product={Hive}
            productname="Hive"
            text="Get your Smart Home set up"
          />
          <Service
            product={Hue}
            productname="Philips Hue"
            text="Light up your Rooms with Hue lights & control them from anywhere"
          />
        </ul>
      </div>
      <div className="MyServices" label="Security">
        <ul className="Service-Group">
          <Service
            product={Login}
            productname="Log In"
            text="Want to learn how you use one account for multipul services"
          />
          <Service
            product={Password}
            productname="Password"
            text="Get organised with your passwords & logins"
          />
          <Service
            product={VPN}
            productname="VPN"
            text="Want to be safe on the web or use services from abroad"
          />
        </ul>
      </div>
    </Tabs>
  </section>
)

export default MyServices
