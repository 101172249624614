import React from "react"
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"

const containerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "16px",
}

const center = {
  lat: 53.878972,
  lng: -2.1893584,
}

function MyMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDwCcmHY9F_ElKuO-XISe_awp_H6Ng1fls",
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <div
      style={{
        textAlign: "left",
      }}
    >
      <h2>Contact Us</h2>
      <p>
        Intrested in getting in touch with us? Please get in touch via email or
        use the from below and we’ll be sure to get back to you.
      </p>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(MyMap)
