import React from "react"
import "./Story.css"
import PropTypes from "prop-types"

export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e)
  }
  render() {
    if (!this.props.show) {
      return null
    }
    return (
      <div class="StoryContents" id="StoryContents">
        {this.props.children}
        <button class="toggle-button" onClick={this.onClose}>
          Close {this.props.person}'s Story
        </button>
      </div>
    )
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}
