import * as React from "react"

const Step = props => (
  <div
    id={"step"}
    style={{
      margin: `0 auto`,
      textAlign: "center",
    }}
  >
    <strong
      style={{
        fontSize: `100px`,
        fontWeight: "bold",
      }}
    >
      {props.number}
    </strong>
    <h3
      style={{
        fontSize: `14pt`,
      }}
    >
      {props.heading}
    </h3>
    <p
      style={{
        fontSize: `16pt`,
      }}
    >
      {props.text}
    </p>
  </div>
)

export default Step
